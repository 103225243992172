<template>
  <v-data-table
    :headers="headers"
    fixed-header
    height="calc(100vh - 270px)"
    :items="items"
    :items-per-page="10"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    hide-default-footer
    item-key="_id"
    disable-pagination
    sort-by="partNumberName"
  />
</template>

<script>
export default {
  name: 'DailyKpiTable',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items () {
      return this.value
    },
  },
}
</script>
