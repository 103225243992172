<template>
  <ag-card-container
    title="Export journalier"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        :disabled="!dailyKpiResult || loadingData"
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>
    <v-container fluid class="py-0">
      <date-selection :loading="loadingData" @validate="fetchData" />
    </v-container>
    <v-tabs
      v-if="dailyKpiResult"
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-for="type in tabList"
        :key="type.data"
        v-text="type.name"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="type in tabList"
        :key="type.data"
      >
        <v-overlay absolute :value="loadingData" z-index="10">
          <v-progress-circular
            style="text-center"
            class="my-8"
            :size="80"
            width="8"
            indeterminate
            color="primary"
          />
        </v-overlay>
        <daily-kpi-table
          v-if="dailyKpiResult"
          :value="dailyKpiResult[type.data]"
          :headers="type.headers"
        />
      </v-tab-item>
    </v-tabs-items>
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import DailyKpiTable from '../components/DailyKpiTable'
import DateSelection from '../components/DateSelection'

export default {
  name: 'ProductionContainer',
  components: {
    AgCardContainer,
    DailyKpiTable,
    DateSelection,
  },
  data: () => ({
    tab: null,
    dailyKpiResult: null,
    loadingData: false,
    loadingExport: false,
  }),
  computed: {
    tabList () {
      return [
        {
          name: 'Bobines fabriquées par références',
          data: 'finishBobineByPartNumber',
          headers: [
            {
              text: 'Référence',
              align: 'center',
              value: 'partNumberName',
            },
            {
              text: 'Quantité',
              align: 'center',
              value: 'count',
            },
          ],
        },
        {
          name: 'Bobines en stock par références',
          data: 'inventoryBobineByPartNumber',
          headers: [
            {
              text: 'Référence',
              align: 'center',
              value: 'partNumberName',
            },
            {
              text: 'Quantité',
              align: 'center',
              value: 'count',
            },
          ],
        },
        {
          name: 'Modules emballés par références',
          data: 'packedModuleByPartNumber',
          headers: [
            {
              text: 'Référence',
              align: 'center',
              value: 'partNumberName',
            },
            {
              text: 'Quantité',
              align: 'center',
              value: 'count',
            },
          ],
        },
        {
          name: 'Tours par N°centri',
          data: 'roundCountByCentri',
          headers: [
            {
              text: 'N°centri',
              align: 'center',
              value: '_id',
            },
            {
              text: 'Nbr de tours',
              align: 'center',
              value: 'tourMax',
            },
          ],
        },
        {
          name: 'Tour par N°µ',
          data: 'roundCountByMicro',
          headers: [
            {
              text: 'N°µ',
              align: 'center',
              value: '_id',
            },
            {
              text: 'Nbr de tours',
              align: 'center',
              value: 'tourMax',
            },
          ],
        },
      ]
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData ({ fromDate, toDate }) {
      try {
        this.loadingData = true
        this.dailyKpiResult = await Repositories.part.getDailyKpi(
          {
            fromDate, toDate,
          },
        )
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const dataArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const type of this.tabList) {
          const exportConfig = {
            columns: [],
            view: [{ state: 'frozen', ySplit: 1 }],
            sheetName: type.name,
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const header of type.headers) {
            exportConfig.columns.push({ header: header.text, key: header.value })
          }
          const data = this.dailyKpiResult[type.data]
          dataArray.push({ dataToExport: data, config: exportConfig })
        }

        await createExcelFile(dataArray, 'Journalier')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>
<style scoped>
.v-menu__content{
  z-index: 9999 !important;
}
</style>
